import { prefixApiUrl, token } from 'config';
import fetch from 'isomorphic-fetch';

export const getArticleCommentsRequest = (payload: any) => {
    const id: any = payload.postId;
    const url = prefixApiUrl + 'api/post/comments/' + id;

    return new Promise(resolve => {
        const headers = {
            // Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        };

        fetch(url, {
            method: 'GET',
            headers,
        })
            .then(response => response.json())
            .then(data => {
                resolve(data);
            });
    });
};
