import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

// import homepage from './ducks/homepage/reducer';
// import news from './ducks/news/reducer';
import about from './ducks/about/reducer';
import { newsfeeds } from './ducks/newsfeeds/reducer';
import { article } from './ducks/article/reducer';
import { comments } from './ducks/comments/reducer';
import shoes from './ducks/shoes/reducer';
// import { news } from '../components/News/reducer';
import { State } from 'types';
import { person } from './ducks/person/reducer';
import { homepage } from './ducks/homepage/reducer';
import { initdata } from './ducks/app/reducer';
import { newsfeed } from './ducks/newsfeed/reducer';
import { navbar } from './ducks/menu/reducer';
import { materials } from './ducks/materials/reducer';
import { banners } from './ducks/banners/reducer';
import { users } from './ducks/users/reducer';
import { subscribe } from './ducks/subscribe/reducer';
import { userAuth } from './ducks/auth/reducer';
import { userRegister } from './ducks/registration/reducer';
import { userRestore } from './ducks/restore/reducer';
import { authors } from './ducks/authors/reducer';
import { notFound } from './ducks/404/reducer';

export default (history: History) =>
    combineReducers<State>({
        // homepage,
        about,
        article,
        comments,
        newsfeeds,
        shoes,
        // news,
        router: connectRouter(history),
        person,
        homepage,
        initdata,
        newsfeed,
        navbar,
        materials,
        banners,
        users,
        subscribe,
        userAuth,
        userRegister,
        userRestore,
        authors,
        notFound,
    });
