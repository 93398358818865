import './Navbar.css';
import { menuSelector, submenuSelector } from 'store/ducks/menu/selector';

import { useDispatch, useSelector } from 'react-redux';

import React, { useEffect } from 'react';
import { MenuItem } from 'types/models';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const menu: { visible: MenuItem[]; hidden: MenuItem[] } = useSelector(
        menuSelector
    );

    const submenu: MenuItem[] = useSelector(submenuSelector);

    return (
        <nav role="navigation" className="navbar">
            <div className="navbar__menu">
                <ul>
                    {menu.visible &&
                        menu.visible.length &&
                        menu.visible.map((item: MenuItem, index: number) => {
                            return (
                                <li key={index}>
                                    <Link to={item.slug}>{item.title}</Link>
                                </li>
                            );
                        })}
                    {menu.hidden &&
                        menu.hidden.length &&
                        menu.hidden.map((item: MenuItem, index: number) => {
                            return (
                                <li key={index}>
                                    <Link to={item.slug}>{item.title}</Link>
                                </li>
                            );
                        })}
                    {submenu &&
                        submenu.length &&
                        submenu.map((item: MenuItem, index: number) => {
                            return (
                                <li key={index}>
                                    <Link to={item.slug}>{item.title}</Link>
                                </li>
                            );
                        })}
                </ul>
            </div>
            <div className="navbar__social">
                <ul>
                    <li>
                        <a href="https://vk.com/telesputnik" target="_blank">
                            <svg className="footer-social__icon footer-social__icon--vk">
                                <use href="/img/sprite.svg#icons--vk"></use>
                            </svg>
                        </a>
                    </li>
                    {/* <li className="footer-social__item">
                    <a href="#" className="footer-social__link">
                        <svg className="footer-social__icon footer-social__icon--facebook">
                            <use href="/img/sprite.svg#icons--facebook"></use>
                        </svg>
                    </a>
                </li> */}
                    <li>
                        <a
                            href="https://ok.ru/group/55408711696413"
                            target="_blank"
                        >
                            <svg className="footer-social__icon footer-social__icon--odn">
                                <use href="/img/sprite.svg#icons--odn"></use>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://telegram.me/telesputnik"
                            target="_blank"
                        >
                            <svg className="footer-social__icon footer-social__icon--telegram">
                                <use href="/img/sprite.svg#icons--telegram"></use>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.youtube.com/channel/UCUZZFbgpPMOdNF8-S6Kj4Cg"
                            target="_blank"
                        >
                            <svg className="footer-social__icon footer-social__icon--youtube">
                                <use href="/img/sprite.svg#icons--youtube"></use>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a
                            href="http://www.telesputnik.ru/rss/"
                            target="_blank"
                        >
                            <svg className="footer-social__icon footer-social__icon--rrs">
                                <use href="/img/sprite.svg#icons--rrs"></use>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a
                            href="mailto:telesputnik@telesputnik.ru"
                            target="_blank"
                        >
                            <svg className="footer-social__icon footer-social__icon--mail">
                                <use href="/img/sprite.svg#icons--mail"></use>
                            </svg>
                        </a>
                    </li>
                    {/* <li className="footer-social__item">
                    <a href="#" className="footer-social__link">
                        <svg className="footer-social__icon footer-social__icon--instagram">
                            <use href="/img/sprite.svg#icons--instagram"></use>
                        </svg>
                    </a>
                </li>
                <li className="footer-social__item">
                    <a href="#" className="footer-social__link">
                        <svg className="footer-social__icon footer-social__icon--twitter">
                            <use href="/img/sprite.svg#icons--twitter"></use>
                        </svg>
                    </a>
                </li> */}
                    <li>
                        <a
                            href="https://zen.yandex.ru/telesputnik"
                            target="_blank"
                        >
                            <svg className="footer-social__icon footer-social__icon--zen">
                                <use href="/img/sprite.svg#icons--zen"></use>
                            </svg>
                        </a>
                    </li>
                    {/* <li>
                        <a href="#">
                            <svg className="navbar__social-icon-vk">
                                <use href="/img/sprite.svg#icons--vk"></use>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <svg className="navbar__social-icon-facebook">
                                <use href="/img/sprite.svg#icons--facebook"></use>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <svg className="navbar__social-icon-odn">
                                <use href="/img/sprite.svg#icons--odn"></use>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <svg className="navbar__social-icon-telegram">
                                <use href="/img/sprite.svg#icons--telegram"></use>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <svg className="navbar__social-icon-youtube">
                                <use href="/img/sprite.svg#icons--youtube"></use>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <svg className="navbar__social-icon-rrs">
                                <use href="/img/sprite.svg#icons--rrs"></use>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <svg className="navbar__social-icon-mail">
                                <use href="/img/sprite.svg#icons--mail"></use>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <svg className="navbar__social-icon-instagram">
                                <use href="/img/sprite.svg#icons--instagram"></use>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <svg className="navbar__social-icon-twitter">
                                <use href="/img/sprite.svg#icons--twitter"></use>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <svg className="navbar__social-icon-zen">
                                <use href="/img/sprite.svg#icons--zen"></use>
                            </svg>
                        </a>
                    </li> */}
                </ul>
            </div>
            <div className="navbar__info">
                <a href="./" className="navbar__logo">
                    <svg>
                        <use href="/img/sprite.svg#icons--logo"></use>
                    </svg>
                </a>
                <p className="navbar__copy">
                    © {new Date().getFullYear()} Телеспутник, <br />
                    Все&nbsp;права&nbsp;защищены
                </p>
            </div>
        </nav>
    );
};

export default Navbar;
