import { call, takeEvery, put, all } from 'redux-saga/effects';

import { authRequest } from './api';

import { auth, authFailure, authSuccess } from './actions';

function* authSaga(action: any) {
    try {
        const { payload } = action;

        const result: { token: string } = yield call(authRequest, payload);

        yield put(authSuccess(result));
    } catch (error) {
        // console.log(error);
        yield put(authFailure(error));
    }
}

export function* authWatcher() {
    yield takeEvery(auth.toString(), authSaga);
}
