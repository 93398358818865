import { createSelector } from 'reselect';

import { Post } from 'types/models';

// const position1 = (state: { banners: { items: { position1: any } } }) =>
//     state.banners.items.position1;
const position2 = (state: { banners: { items: { position2: any } } }) =>
    state.banners.items.position2;
const position3 = (state: { banners: { items: { position3: any } } }) =>
    state.banners.items.position3;
const position4 = (state: { banners: { items: { position4: any } } }) =>
    state.banners.items.position4;
const position5 = (state: { banners: { items: { position5: any } } }) =>
    state.banners.items.position5;
const position6 = (state: { banners: { items: { position6: any } } }) =>
    state.banners.items.position6;
const position7 = (state: { banners: { items: { position7: any } } }) =>
    state.banners.items.position7;
const position8 = (state: { banners: { items: { position8: any } } }) =>
    state.banners.items.position8;
const position9 = (state: { banners: { items: { position9: any } } }) =>
    state.banners.items.position9;
const position17 = (state: { banners: { items: { position17: any } } }) =>
    state.banners.items.position17;
const position18 = (state: { banners: { items: { position18: any } } }) =>
    state.banners.items.position18;
const position20 = (state: { banners: { items: { position20: any } } }) =>
    state.banners.items.position20;
const position14 = (state: { banners: { items: { position14: any } } }) =>
    state.banners.items.position14;
const position15 = (state: { banners: { items: { position15: any } } }) =>
    state.banners.items.position15;

const getPercentBanner = (banners: any) => {
    let banner = null;
    let totalPercent = 0;

    if (banners && banners.length) {
        let customBanners: any = [];

        banners.forEach((banner: { procent: number }) => {
            let percent = (banner.procent && banner.procent) || 1;

            totalPercent = totalPercent + percent;
        });

        let startPercent: any = 0;

        banners.forEach((banner: { procent: number }) => {
            let percent: any = (banner.procent && banner.procent) || 1;

            customBanners.push({
                ...banner,
                percentFrom: parseInt(startPercent),
                percentTo:
                    parseInt(((percent / totalPercent) * 100).toFixed(0)) +
                    parseInt(startPercent),
            });

            startPercent =
                parseInt(((percent / totalPercent) * 100).toFixed(0)) +
                parseInt(startPercent);
        });

        let randomPercent = parseInt((Math.random() * 100).toFixed(0));

        banner = customBanners.filter(
            (banner: { percentFrom: number; percentTo: number }) => {
                return (
                    banner.percentFrom <= randomPercent &&
                    banner.percentTo >= randomPercent
                );
            }
        );
        if (banner.length > 0) {
            banner = banner[0];
        }
    }

    return banner;
};

// export const selectPosition1Banner = createSelector([position1], banners => {
//     return getPercentBanner(banners);
// });

export const selectPosition2Banner = createSelector([position2], banners => {
    return getPercentBanner(banners);
});

export const selectPosition3Banner = createSelector([position3], banners => {
    return getPercentBanner(banners);
});

export const selectPosition4Banner = createSelector([position4], banners => {
    return getPercentBanner(banners);
});

export const selectPosition5Banner = createSelector([position5], banners => {
    return getPercentBanner(banners);
});

export const selectPosition6Banner = createSelector([position6], banners => {
    return getPercentBanner(banners);
});

export const selectPosition7Banner = createSelector([position7], banners => {
    return getPercentBanner(banners);
});

export const selectPosition8Banner = createSelector([position8], banners => {
    return getPercentBanner(banners);
});

export const selectPosition9Banner = createSelector([position9], banners => {
    return getPercentBanner(banners);
});

export const selectPosition17Banner = createSelector([position17], banners => {
    return getPercentBanner(banners);
});

export const selectPosition18Banner = createSelector([position18], banners => {
    return getPercentBanner(banners);
});

export const selectPosition20Banner = createSelector([position20], banners => {
    return getPercentBanner(banners);
});

export const selectPosition14Banner = createSelector([position14], banners => {
    return getPercentBanner(banners);
});

export const selectPosition15Banner = createSelector([position15], banners => {
    return getPercentBanner(banners);
});
