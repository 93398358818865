import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import {
    getNewsfeeds,
    getNewsfeedsIdle,
    getNewsfeedsSuccess,
    getNewsfeedsFailure,
    getNewsfeedsClear,
} from './actions';
import { NewsfeedsState } from './types';
import { Post, StateStatus } from 'types/models';

export const initialState: NewsfeedsState = {
    status: 'IDLE',
    items: [],
    paginate: null,
    title: '',
    typeTitle: '',
};

const status = handleActions<StateStatus>(
    {
        [getNewsfeeds]: () => 'LOADING',
        [getNewsfeedsSuccess]: () => 'SUCCESS',
        [getNewsfeedsIdle]: () => 'IDLE',
        [getNewsfeedsFailure]: () => 'FAILURE',
    },
    initialState.status
);

const items = handleActions(
    {
        [getNewsfeedsSuccess]: (
            state,
            action: { payload: { posts: Post[] } }
        ) => [...state, ...action.payload.posts],
        [getNewsfeedsClear]: () => [],
    },
    initialState.items
);

const title = handleActions(
    {
        [getNewsfeedsSuccess]: (
            state,
            action: { payload: { title: string } }
        ) => action.payload.title,
        [getNewsfeedsClear]: () => '',
    },
    initialState.title
);

const typeTitle = handleActions(
    {
        [getNewsfeedsSuccess]: (
            state,
            action: { payload: { type_title: string } }
        ) => action.payload.type_title,
        [getNewsfeedsClear]: () => '',
    },
    initialState.typeTitle
);

const paginate = handleActions(
    {
        [getNewsfeedsSuccess]: (
            state,
            action: {
                payload: {
                    paginate: {
                        current_page: any;
                        total_pages: any;
                        total_posts: any;
                    };
                };
            }
        ) => {
            return action.payload.paginate;
        },
        [getNewsfeedsClear]: () => null,
    },
    initialState.paginate
);

export const newsfeeds = combineReducers({
    status,
    items,
    paginate,
    title,
    typeTitle,
});
