import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import {
    addSubscribe,
    addSubscribeFailure,
    addSubscribeIdle,
    addSubscribeSuccess,
} from './actions';

import { StateStatus } from 'types/models';

import { SubscribeState } from './types';

export const initialState: SubscribeState = {
    status: 'IDLE',
    message: '',
};

const message = handleActions(
    {
        [addSubscribeSuccess]: (state, action) => action.payload,
        [addSubscribeFailure]: (state, action) => action.payload,
    },
    initialState.message
);

const status = handleActions<StateStatus>(
    {
        [addSubscribe]: () => 'LOADING',
        [addSubscribeSuccess]: () => 'SUCCESS',
        [addSubscribeIdle]: () => 'IDLE',
    },
    initialState.status
);

export const subscribe = combineReducers({
    status,
    message,
});
