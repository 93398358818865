import { call, takeEvery, put, all } from 'redux-saga/effects';

import { getUserRequest } from './api';

import { getUser, getUserFailure, getUserSuccess } from './actions';

function* getSaga(action: any) {
    try {
        const { payload } = action;

        const result: [] = yield call(getUserRequest, payload);

        yield put(getUserSuccess(result));
    } catch (error) {
        // console.log(error);
        yield put(getUserFailure(error));
    }
}

export function* usersWatcher() {
    yield takeEvery(getUser.toString(), getSaga);
}
