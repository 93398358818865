import { call, takeEvery, put, all } from 'redux-saga/effects';

import { getNewsfeedsRequest } from './api';

import {
    getNewsfeeds,
    getNewsfeedsFailure,
    getNewsfeedsSuccess,
} from './actions';
import { Post } from 'types/models';

function* getSaga(action: {
    payload: {
        type: string;
        taxonomy: string;
        page: number;
        offset: number;
        offsetCount: number;
    };
}) {
    try {
        const { payload } = action;
        const response: Post[] = yield call(getNewsfeedsRequest, payload);
        yield put(getNewsfeedsSuccess(response));
    } catch (error) {
        console.log(error);
        yield put(getNewsfeedsFailure(error));
    }
}

export function* newsfeedsWatcher() {
    yield takeEvery(getNewsfeeds.toString(), getSaga);
}
