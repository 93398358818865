import * as React from 'react';
import './FooterMenu.css';

export function FooterMenu() {
    return (
        <div className="footer-info footer__info">
            <a href="./" className="logo logo--white footer__logo">
                <svg className="logo__img">
                    <use href="/img/sprite.svg#icons--logo"></use>
                </svg>
            </a>
            <p className="footer__copy">
                © {new Date().getFullYear()} Телеспутник,
                Все&nbsp;права&nbsp;защищены
            </p>
        </div>
    );
}
