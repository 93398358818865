import { prefixApiUrl, token } from 'config';
import fetch from 'isomorphic-fetch';

export const renewRequest = (payload: {
    email: string;
    token: string;
    password: string;
    password_confirmation: string;
}) => {
    let { email, token, password, password_confirmation } = payload;

    let url = prefixApiUrl + 'api/reset-password';

    const headers = {
        // Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    const body = {
        email,
        token,
        password,
        password_confirmation,
    };

    return fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body),
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            // console.log('restore error', error)
        });
};
