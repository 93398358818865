import { prefixApiUrl, token } from 'config';
import fetch from 'isomorphic-fetch';

export const getNewsfeedRequest = () => {
    // const url = prefixApiUrl + 'api/post/index///1/5';
    const url = prefixApiUrl + 'api/taxonomy/7/posts/created_at/DESC/1/5';

    return new Promise((resolve, reject) => {
        const headers = {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        };

        fetch(url, {
            method: 'GET',
            headers,
        })
            .then(response => response.json())
            .then(data => {
                resolve(data.posts);
            });
    });
};
