import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { getAuthors, getAuthorsSuccess, getAuthorsIdle } from './actions';
import { AuthorsState, StateStatus } from './types';

export const initialState: AuthorsState = {
    status: 'IDLE',
    list: [],
    total_pages: 1,
    curret_page: 1
};

const status = handleActions<StateStatus>(
    {
        [getAuthors]: () => 'LOADING',
        [getAuthorsSuccess]: () => 'SUCCESS',
        [getAuthorsIdle]: () => 'IDLE',
    },
    initialState.status
);

const list = handleActions(
    {
        [getAuthorsSuccess]: (state, action: { payload: { authors: any } }) =>
            action.payload.authors,
    },
    initialState.list
);

const total_pages = handleActions(
    {
        [getAuthorsSuccess]: (state, action) =>
            action.payload.total_pages,
    },
    initialState.total_pages
);

const curret_page = handleActions(
    {
        [getAuthorsSuccess]: (state, action) =>
            action.payload.curret_page,
    },
    initialState.curret_page
);

export const authors = combineReducers({
    status,
    list,
    total_pages,
    curret_page
});
