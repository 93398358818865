import React from 'react';
import './Editorial.css';
import cx from 'classnames';
import { Tag, MoreArrow } from 'components';
import { useSelector } from 'react-redux';

export function Editorial() {

    const editorial = useSelector((state: { initdata: { editorial: any[] } }) => state.initdata.editorial);

    return (
        <div className="editorial">
            <h2 className="editorial__title">Редакция</h2>
            <ul className="editorial__list">

                {editorial && editorial.length > 0 && editorial.map((item: any) => {
                    let { email, username, surname, description, phone, avatar, profession } = item;
                    return (
                        <li className="editorial-item editorial__item">
                            <div className="editorial-item__wrap-img">
                                {avatar && (
                                    <img
                                        src={avatar.indexOf('https') !== -1 ? avatar : 'https://api.telesputnik.ru/storage/' + avatar}
                                        alt="img"
                                        className="editorial-item__img"
                                    />
                                )}
                            </div>
                            <div className="editorial-item__container">
                                <div className="editorial-item__wrapper">
                                    <h4 className="editorial-item__title">
                                        {username} {surname}
                                    </h4>
                                    <span className="editorial-item__subtitle">
                                        {profession}
                                    </span>
                                </div>
                                {phone && (
                                    <a href={`tel:${phone}`} className="editorial-item__link">
                                        <svg className="editorial-item__icon">
                                            <use href="/img/sprite.svg#icons--phone"></use>
                                        </svg>
                                        {phone}
                                    </a>
                                )}
                                <a href={`mailto:telesputnik@telesputnik.ru`} className="editorial-item__link">
                                    <svg className="editorial-item__icon">
                                        <use href="/img/sprite.svg#icons--email"></use>
                                    </svg>
                                    Написать письмо
                                </a>
                            </div>
                        </li>
                    )
                })}
                
            </ul>
        </div>
    );
}
