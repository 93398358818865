import { ReduxAction } from 'types';

// types
export const IS_404 = 'IS_404';
export const IS_404_IDLE = 'IS_404_IDLE';

// action types
export type is404Action = ReduxAction<typeof IS_404>;
export type is404IdleAction = ReduxAction<typeof IS_404_IDLE>;

export type StateStatus = '404' | 'IDLE';

export interface State404 {
    readonly status: StateStatus;
}
