import * as React from 'react';
import cx from 'classnames';
import './MoreArrow.css';
import { Link } from 'react-router-dom';
type Props = {
    children: React.ReactNode[] | React.ReactNode;
    type: string;
    link: string;
};

export function MoreArrow(props: Props) {
    const { type, link, children } = props;
    return (
        <>
            {type && type === 'newsfeed' && (
                <Link to={link} className="more more--purple newsfeed__more">
                    {children}
                    <svg className="more__icon">
                        <use href="/img/sprite.svg#icons--arrow-right"></use>
                    </svg>
                </Link>
            )}

            {type && type === 'articles' && (
                <Link to={link} className="more more--purple articles__more">
                    {children}
                    <svg className="more__icon">
                        <use href="/img/sprite.svg#icons--arrow-right"></use>
                    </svg>
                </Link>
            )}

            {type && type === 'person' && (
                <Link to={link} className="more more--purple person__more">
                    {children}
                    <svg className="more__icon">
                        <use href="/img/sprite.svg#icons--arrow-right"></use>
                    </svg>
                </Link>
            )}

            {type && type === 'video' && (
                <Link to={link} className="more more--purple video__more">
                    {children}
                    <svg className="more__icon">
                        <use href="/img/sprite.svg#icons--arrow-right"></use>
                    </svg>
                </Link>
            )}

            {!type && (
                <Link to={link} className="more news__more">
                    {children}
                    <svg className="more__icon">
                        <use href="/img/sprite.svg#icons--arrow-right"></use>
                    </svg>
                </Link>
            )}
        </>
    );
}
