import * as React from 'react';
import './Documents.css';
import { Btn } from 'components';
import cx from 'classnames';

const documents = [
    {
        id: '1',
        img: '/img/documents-img-1.jpg',
        title: 'Свидетельство о регистрации ЭЛ&nbsp;№77-4109 от 22.09.2000 г.',
        link: '/img/documents-img-1.jpg',
    },
    {
        id: '2',
        img: '/img/documents-img-2.jpg',
        title: 'Свидетельство о регистрации ПИ&nbsp;№77-14573 от 20.01.2003 г.',
        link: '/img/documents-img-2.jpg',
    },
    {
        id: '3',
        img: '/img/documents-img-3.jpg',
        title: 'Свидетельство на товарный знак №677794',
        link: '/img/documents-img-3.jpg',
    },
];
export function Documents() {
    return (
        <div className="documents">
            <h2 className="documents__title">Документы</h2>
            <ul className="documents__list">
                {documents.map(item => (
                    <li className="documents__items">
                        <a href={item.link} target="_blank" className="documents__link">
                            <div className="documents__wrap-img">
                                <img
                                    src={item.img}
                                    alt="img"
                                    className="documents__img"
                                />
                            </div>
                            <div
                                dangerouslySetInnerHTML={{ __html: item.title }}
                            ></div>
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
}
