import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './Others.css';
import { Quiz, ArticlePostVideo, Promo, ArticlePost } from 'components';
import { pathMaterials } from 'config';
import {
    getMaterialsLoadMore,
    getMaterialsLoadMoreClear,
    getMaterialsLoadMoreIdle,
} from 'store/ducks/homepage/actions';
import { firstActiveQuizSelector } from 'store/ducks/homepage/selectors/firstActiveQuizSelector';
import { videosSelector } from 'store/ducks/homepage/selectors/videosSelector';
import { materialsLoadMoreSelector } from 'store/ducks/homepage/selectors/materialsLoadMoreSelector';
import { ArticlePostSingle } from 'components/ArticlePostSingle/ArticlePostSingle';
import { eventsSelector } from 'store/ducks/homepage/selectors/eventsSelector';
import { materialsLoadMoreFirstSelector } from 'store/ducks/homepage/selectors/materialsLoadMoreFirstSelector';
import { Link } from 'react-router-dom';
import { articlesListSelector } from 'store/ducks/homepage/selectors/articlesListSelector';
import { Post } from 'types/models';
import { selectPosition14Banner, selectPosition15Banner } from 'store/ducks/banners/selectors';

export function Others() {
    const dispatch = useDispatch();

    // const [page, setPage] = useState(2);
    const [offset, setOffset] = useState(5);

    const { status, statusMaterialsLoadMore } = useSelector(
        (state: {
            homepage: { status: string; statusMaterialsLoadMore: string };
        }) => state.homepage
    );

    const position14Banner = useSelector(selectPosition14Banner);
    const position15Banner = useSelector(selectPosition15Banner);

    // const firstActiveQuiz = useSelector(firstActiveQuizSelector);
    const firstActiveQuiz = false;
    const videos = useSelector(videosSelector);
    let articlesList = useSelector(articlesListSelector);
    const materialsLoadMore = useSelector(materialsLoadMoreSelector);
    const materialsLoadMoreFirst = useSelector(materialsLoadMoreFirstSelector);
    const materialsLoadMorePaginate = useSelector(
        (state: { homepage: { materialsLoadMorePaginare: any } }) =>
            state.homepage.materialsLoadMorePaginare
    );

    useEffect(() => {
        if (position14Banner){
            setOffset(offset - 1);
            articlesList.pop();
        }
    }, [position14Banner])

    useEffect(() => {
        if (position15Banner){
            setOffset(offset - 1);
            articlesList.pop();
        }
    }, [position15Banner])

    useEffect(() => {
        if (statusMaterialsLoadMore == 'SUCCESS') {
            setOffset(offset + materialsLoadMore.length);
            dispatch(getMaterialsLoadMoreIdle());
        }
    }, [statusMaterialsLoadMore]);

    useEffect(() => {
        if (statusMaterialsLoadMore == 'IDLE') {
            dispatch(getMaterialsLoadMoreClear());
            // dispatch(getMaterialsLoadMore({ page: 2 }));
        }
    }, []);

    return (
        <section className="others page__others">
            <div className="others__wrapper">
                {status == 'SUCCESS' && (
                    <>
                        {(firstActiveQuiz && (
                            <>
                                <Quiz {...firstActiveQuiz} />
                                <ArticlePostVideo {...videos[0]} />
                            </>
                        )) || (
                            <>
                                <ArticlePostSingle
                                    {...articlesList[0]}
                                    type="others"
                                />
                                <ArticlePostSingle
                                    {...articlesList[1]}
                                    type="others"
                                />
                                {/* <ArticlePostVideo {...videos[0]} /> */}
                            </>
                        )}
                    </>
                )}
            </div>
            <div className="materials others__materials">
                <ul className="materials__list">
                    {position14Banner && (
                        <li
                            className="materials__item _banner"
                            // key={'123'}
                            key={position14Banner.id}
                        >
                            <Link
                                // to={'https://api.telesputnik.ru/storage/images/ads/wJFQHGB5gzVaq8EMkUaMPs3HV99DkZAjRUSga0lQ.jpg'}
                                to={position14Banner.link}
                                // className="materials__link _banner"
                            >
                                <img
                                    src={position14Banner.img}
                                    alt={position14Banner.title}
                                    // src={'https://api.telesputnik.ru/storage/images/ads/wJFQHGB5gzVaq8EMkUaMPs3HV99DkZAjRUSga0lQ.jpg'}
                                    // alt={'123'}
                                    className="materials__img"
                                />
                            </Link>
                        </li>
                    )}
                    {articlesList &&
                        articlesList.length > 0 && 
                        articlesList.map((item, index) => {
                            if (index > 1){
                                return (
                                    <li
                                        className="materials__item"
                                        key={item.id}
                                    >
                                        <Link
                                            to={item.link}
                                            className="materials__link"
                                        >
                                            <img
                                                src={item.img}
                                                alt={item.title}
                                                className="materials__img"
                                            />
                                            <div className="materials__common">
                                                <h3
                                                    className="materials__title"
                                                    dangerouslySetInnerHTML={{
                                                        __html: item.title,
                                                    }}
                                                ></h3>
                                                <div className="materials__date">{item.date}</div>
                                            </div>
                                        </Link>
                                    </li>
                                )
                            }
                        })}
                    {position15Banner && (
                        <li
                            className="materials__item _banner _left"
                            key={position15Banner.id}
                            // key={'123'}
                        >
                            <Link
                                // to={'https://api.telesputnik.ru/storage/images/ads/wJFQHGB5gzVaq8EMkUaMPs3HV99DkZAjRUSga0lQ.jpg'}
                                to={position15Banner.link}
                                className="materials__link"
                            >
                                <img
                                    // src={'https://api.telesputnik.ru/storage/images/ads/wJFQHGB5gzVaq8EMkUaMPs3HV99DkZAjRUSga0lQ.jpg'}
                                    // alt={'123'}
                                    src={position15Banner.img}
                                    alt={position15Banner.title}
                                    className="materials__img"
                                />
                            </Link>
                        </li>
                    )}
                    {materialsLoadMore &&
                        materialsLoadMore.length > 0 &&
                        materialsLoadMore.map(
                            (
                                item: {
                                    title: string;
                                    id: string;
                                    img: string;
                                    link: string;
                                    date: string;
                                },
                                index
                            ) => {
                                return (
                                    <li
                                        className="materials__item"
                                        key={item.id}
                                    >
                                        <Link
                                            to={item.link}
                                            className="materials__link"
                                        >
                                            <img
                                                src={item.img}
                                                alt="#"
                                                className="materials__img"
                                            />
                                            <div className="materials__common">
                                                <h3
                                                    className="materials__title"
                                                    dangerouslySetInnerHTML={{
                                                        __html: item.title,
                                                    }}
                                                ></h3>
                                                <div className="materials__date">{item.date}</div>
                                            </div>
                                        </Link>
                                    </li>
                                );
                            }
                        )}
                </ul>
                <button
                    className="btn materials__btn"
                    onClick={e => {
                        e.preventDefault();

                        if (statusMaterialsLoadMore == 'IDLE') {
                            // setPage(page + 1);
                            dispatch(
                                getMaterialsLoadMore({ offset: offset })
                            );
                        }
                    }}
                >
                    Больше материалов
                </button>
            </div>
        </section>
    );
}
