import { prefixApiUrl, token } from 'config';
import fetch from 'isomorphic-fetch';

export const setLikesRequest = (payload: any) => {
    const id = payload.id;
    const like = payload.like;
    const type = payload.type;

    const url = prefixApiUrl + 'api/like';

    return new Promise((resolve, reject) => {
        const headers = {
            Authorization: 'Bearer ',
            'Content-Type': 'application/json',
            Accept: 'application/json',
        };
        let body = {
            parent_id: id,
            type: type,
            like: like,
        };
        fetch(url, {
            method: 'POST',
            headers,
            body: JSON.stringify(body),
        })
            .then(response => response.json())
            .then(data => {
                resolve(data);
            });
    });
};
