import { prefixApiUrl, token } from 'config';
import fetch from 'isomorphic-fetch';

export const getVideosRequest = () => {
    const url = prefixApiUrl + 'api/taxonomyitem/posts/0/35/publication_at/DESC/1/4';
    // const url = prefixApiUrl + 'api/taxonomyitem/postsbyslugoffsetlimit/type-content/video/content/publication_at/DESC/0/4';

    const headers = {
        // Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    return fetch(url, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => data.posts);
};
