import * as React from 'react';
import './AboutProjectAdvertising.css';
import { Btn } from 'components';
import cx from 'classnames';

export function AboutProjectAdvertising() {
    return (
        <div className="about-project-advertising about-project__advertising">
            <h2 className="about-project-advertising__title">Реклама</h2>
            <p className="about-project-advertising__text">
                Чтобы с нами связаться, напишите на почту
                <a href="mailto:telesputnik@telesputnik.ru" target="_blank" className="about-project-advertising__text-link">
                    telesputnik@telesputnik.ru
                </a>
            </p>
            <div className="contacts-man">
                <ul className="contacts-man__list">
                    <li className="contacts-man__item">
                        <div className="contacts-man__wrap-img">
                            <img
                                src="/img/Turchenko-N.S..jpg"
                                alt="img"
                                className="contacts-man__img"
                            />
                        </div>
                        <h4 className="contacts-man__title">
                            Турченко Алексей Станиславович
                        </h4>
                        <span className="contacts-man__subtitle">Директор по развитию</span>
                        {/* <a href="tel:000000" className="contacts-man__link">
                            <svg className="about-project-contacts__img">
                                <use href="/img/sprite.svg#icons--phone"></use>
                            </svg>
                            00-00-00
                        </a> */}
                        <a href="mailto:telesputnik@telesputnik.ru" className="contacts-man__link">
                            <svg className="about-project-contacts__img">
                                <use href="/img/sprite.svg#icons--email"></use>
                            </svg>
                            telesputnik@telesputnik.ru
                        </a>
                    </li>
                    {/* <li className="contacts-man__item">
                        <div className="contacts-man__wrap-img">
                            <img
                                src="/img/about-project-advertising-img-2.jpg"
                                alt="img"
                                className="contacts-man__img"
                            />
                        </div>
                        <h4 className="contacts-man__title">
                            Иванов Иван Иванович
                        </h4>
                        <span className="contacts-man__subtitle">менеджер</span>
                        <a href="tel:000000" className="contacts-man__link">
                            <svg className="about-project-contacts__img">
                                <use href="/img/sprite.svg#icons--phone"></use>
                            </svg>
                            00-00-00
                        </a>
                        <a href="#" className="contacts-man__link">
                            <svg className="about-project-contacts__img">
                                <use href="/img/sprite.svg#icons--email"></use>
                            </svg>
                            reklama@telesputnik.ru
                        </a>
                    </li> */}
                </ul>
                <p className="about-project-advertising__text">
                    Мы ждем вашего письма или звонка!
                </p>
            </div>
        </div>
    );
}
