import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import {
    getMaterials,
    getMaterialsIdle,
    getMaterialsSuccess,
    getMaterialsClear,
    getMaterialsFailure
} from './actions';
import { MaterialsState } from './types';
import { Post, StateStatus } from 'types/models';

export const initialState: MaterialsState = {
    status: 'IDLE',
    items: [],
    paginate: null,
};

const status = handleActions<StateStatus>(
    {
        [getMaterials]: () => 'LOADING',
        [getMaterialsSuccess]: () => 'SUCCESS',
        [getMaterialsIdle]: () => 'IDLE',
        [getMaterialsFailure]: () => 'FAILURE',
    },
    initialState.status
);

const items = handleActions(
    {
        [getMaterialsSuccess]: (
            state,
            action: { payload: { posts: Post[] } }
        ) => [...state, ...action.payload.posts],
        [getMaterialsClear]: () => [],
    },
    initialState.items
);

const paginate = handleActions(
    {
        [getMaterialsSuccess]: (
            state,
            action: {
                payload: { paginate: { current_page: any; total_pages: any } };
            }
        ) => {
            return action.payload.paginate;
        },
        [getMaterialsClear]: () => null,
    },
    initialState.paginate
);

export const materials = combineReducers({
    status,
    items,
    paginate,
});
