import * as React from 'react';
import './PostPerson.css';

import { Link } from 'react-router-dom';
import { pathPersony } from 'config';

type Props = {
    id: string;
    slug: string;
    preview: string;
    title: string;
    status: string;
};

export function PostPerson(props: Props) {
    const { id, slug, preview, title, status } = props;
    return (
        <article className="post-person person__item" key={id}>
            <div className="post-person__wrapper-img">
                <Link to={slug}><img src={preview} alt="img" className="post-person__img" /></Link>
            </div>
            <Link to={slug} className="post-person__title">
                <span dangerouslySetInnerHTML={{ __html: title }} />
            </Link>
        </article>
    );
}
