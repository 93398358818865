import * as React from 'react';
import cx from 'classnames';
import './Btn.css';
type Props = {
    children: React.ReactNode[] | React.ReactNode;
    type: string;
    mod: string;
    handleClick: any;
    disable?: any;
};

export function Btn(props: Props) {
    const { mod, type, children, handleClick, disable } = props;
    return (
        <>
            <button
                disabled={disable}
                className={cx(
                    'btn',
                    mod && mod === 'purple' && 'btn--purple',
                    type && type === 'subscription' && 'subscription__btn',
                    type && type === 'quiz' && 'quiz__btn',
                    type && type === 'page-newsfeed' && 'page-newsfeed__btn'
                )}
                type="submit"
                onClick={handleClick}
            >
                {children}
            </button>
        </>
    );
}
