import { createActions } from 'redux-actions';

const {
    getPerson,
    getPersonSuccess,
    getPersonFailure,
    getPersonIdle,
}: {
    getPerson?: any;
    getPersonSuccess?: any;
    getPersonFailure?: any;
    getPersonIdle?: any;
} = createActions(
    'GET_PERSON',
    'GET_PERSON_SUCCESS',
    'GET_PERSON_FAILURE',
    'GET_PERSON_IDLE'
);

export { getPerson, getPersonSuccess, getPersonFailure, getPersonIdle };
