import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, SubmitHandler } from 'react-hook-form';
import { articleCommentsSelector } from 'store/ducks/comments/selectors';
import { getCommentsArticle } from 'store/ducks/comments/actions/commentsActions';
import { addCommentsArticle } from 'store/ducks/comments/actions/addCommentsActions';
import { useParams } from 'react-router-dom';
import { Likes } from '../..';
import { StateStatus } from 'types/models';

type Props = {
    id: number;
    key: string;
    avatar: string;
    fio: string;
    date: string;
    text: string;
    like: any;
    dislike: any;
};

export function Comment(props: Props) {
    const { key, avatar, fio, date, text, id, like, dislike } = props;

    return (
        <li className="comment article-comments__item" key={key}>
            <div className="comment__img">
                <img src={avatar} alt="img" />
            </div>
            <div className="comment__content">
                <div className="comment__header">
                    <div className="comment__name">{fio}</div>
                    <div className="comment__date">{date}</div>
                </div>
                <div className="comment__text">{text}</div>
                <Likes
                    postId={id}
                    like={like}
                    dislike={dislike}
                    type="comments"
                />
            </div>
        </li>
    );
}
