export const getIpAddressRequest = () => {

  return fetch('https://api.db-ip.com/v2/free/self', {
    method: 'GET',
    redirect: 'follow'
  })
    .then(response => response.json())
    .then(data => data.ipAddress);
    
};
