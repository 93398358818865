import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { getNewsfeed, getNewsfeedIdle, getNewsfeedSuccess } from './actions';
import { NewsfeedState } from './types';
import { Post, StateStatus } from 'types/models';

export const initialState: NewsfeedState = {
    status: 'IDLE',
    items: [],
};

const status = handleActions<StateStatus>(
    {
        [getNewsfeed]: () => 'LOADING',
        [getNewsfeedSuccess]: () => 'SUCCESS',
        [getNewsfeedIdle]: () => 'IDLE',
    },
    initialState.status
);

const items = handleActions(
    {
        [getNewsfeedSuccess]: (state, action: { payload: Post[] }) =>
            action.payload,
    },
    initialState.items
);

export const newsfeed = combineReducers({
    status,
    items,
});
