import { call, takeEvery, put, all } from 'redux-saga/effects';

import { getArticleCommentsRequest, addArticleCommentsRequest } from './api';

import {
    getCommentsArticle,
    getCommentsArticleSuccess,
    getCommentsArticleFailure,
} from './actions/commentsActions';

import {
    addCommentsArticle,
    addCommentsArticleSuccess,
    addCommentsArticleFailure,
} from './actions/addCommentsActions';

function* getSaga(action: any) {
    try {
        const { payload } = action;
        const resultComments: [] = yield call(
            getArticleCommentsRequest,
            payload
        );
        yield put(getCommentsArticleSuccess(resultComments));
    } catch (error) {
        // console.log(error);
        yield put(getCommentsArticleFailure(error));
    }
}

function* getSagaAddCom(action: any) {
    try {
        const { payload } = action;
        const resultComments: [] = yield call(
            addArticleCommentsRequest,
            payload
        );
        yield put(addCommentsArticleSuccess(resultComments));
    } catch (error) {
        // console.log(error);
        yield put(addCommentsArticleFailure(error));
    }
}

export function* commentsWatcher() {
    yield takeEvery(getCommentsArticle.toString(), getSaga);
    yield takeEvery(addCommentsArticle.toString(), getSagaAddCom);
}
