import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import './Newsfeed.css';
import { PostNewsfeed, MoreArrow } from 'components';
import { getNewsfeed } from 'store/ducks/newsfeed/actions';
import { pathNewsfeed } from 'config';
import { newsfeedSelector } from 'store/ducks/newsfeed/selector';
import { eventsSelector } from 'store/ducks/homepage/selectors/eventsSelector';

export function Newsfeed() {
    const dispatch = useDispatch();

    let status = useSelector(
        (state: { newsfeed: { status: string } }) => state.newsfeed.status
    );

    let newsfeedItems = useSelector(eventsSelector);

    useEffect(() => {
        if (status == 'IDLE') {
            dispatch(getNewsfeed());
        }
    }, []);

    return (
        <section className="newsfeed page__newsfeed">
            <h2 className="page-title newsfeed__page-title">Лента</h2>
            <MoreArrow type="newsfeed" link={pathNewsfeed}>
                Все материалы{' '}
            </MoreArrow>
            {status == 'SUCCESS' &&
                newsfeedItems.length > 0 &&
                newsfeedItems.map((item, index) => {
                    return <PostNewsfeed key={item.id} {...item} />;
                })}
        </section>
    );
}
