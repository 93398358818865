import { IS_404, IS_404_IDLE, State404, StateStatus } from './types';
import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';

export const initialState: State404 = {
    status: 'IDLE',
};

const status = handleActions<StateStatus>(
    {
        [IS_404]: () => '404',
        [IS_404_IDLE]: () => 'IDLE',
    },
    initialState.status
);
export const notFound = combineReducers({
    status,
});
