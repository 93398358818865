import { call, takeEvery, put, all } from 'redux-saga/effects';
import { restoreRequest, renewRequest } from './api';
import {
    restoreUser,
    restoreUserFailure,
    restoreUserSuccess,
    renewUser,
    renewUserFailure,
    renewUserSuccess,
} from './actions';

function* restoreUserSaga(action: any) {
    try {
        const { payload } = action;
        const result: any[] = yield call(restoreRequest, payload);
        yield put(restoreUserSuccess(result));
    } catch (error) {
        // console.log('error: ', error);
        yield put(restoreUserFailure(error));
    }
}

function* renewUserSaga(action: any) {
    try {
        const { payload } = action;
        const result: any[] = yield call(renewRequest, payload);
        yield put(renewUserSuccess(result));
    } catch (error) {
        // console.log('error: ', error);
        yield put(renewUserFailure(error));
    }
}

export function* restoreUserWatcher() {
    yield takeEvery(restoreUser.toString(), restoreUserSaga);
    yield takeEvery(renewUser.toString(), renewUserSaga);
}
