import * as React from 'react';
import { Link } from 'react-router-dom';
import './FooterInfo.css';

export function FooterInfo() {
    return (
        <div className="footer-info footer__info">
            <Link to={'/'} className="logo logo--white footer__logo">
                <img src="/img/icons/logo-short-white.svg" />
            </Link>
            <p className="footer__copy">
                © {new Date().getFullYear()} Телеспутник,
                Все&nbsp;права&nbsp;защищены
            </p>
        </div>
    );
}
