import { call, takeEvery, put, all } from 'redux-saga/effects';

import { getBannersRequest } from './api';

import { getBanners, getBannersFailure, getBannersSuccess } from './actions';

function* getSaga() {
    try {
        const [
            // position1Banners,
            // position2Banners,
            // position3Banners,
            position4Banners,
            position5Banners,
            position6Banners,
            position7Banners,
            position8Banners,
            position9Banners,
            position17Banners,
            position18Banners,
            position20Banners,
            position14Banners,
            position15Banners,
        ]: any[] = yield all([
            // call<any>(getBannersRequest, { id: '1' }),
            // call<any>(getBannersRequest, { id: '2' }),
            // call<any>(getBannersRequest, { id: '3' }),
            call<any>(getBannersRequest, { id: '4' }),
            call<any>(getBannersRequest, { id: '5' }),
            call<any>(getBannersRequest, { id: '6' }),
            call<any>(getBannersRequest, { id: '7' }),
            call<any>(getBannersRequest, { id: '8' }),
            call<any>(getBannersRequest, { id: '9' }),
            call<any>(getBannersRequest, { id: '17' }),
            call<any>(getBannersRequest, { id: '18' }),
            call<any>(getBannersRequest, { id: '20' }),
            call<any>(getBannersRequest, { id: '21' }),
            call<any>(getBannersRequest, { id: '22' }),
        ]);

        const banners = {
            // position1: position1Banners,
            // position2: position2Banners,
            // position3: position3Banners,
            position4: position4Banners,
            position5: position5Banners,
            position6: position6Banners,
            position7: position7Banners,
            position8: position8Banners,
            position9: position9Banners,
            position17: position17Banners,
            position18: position18Banners,
            position20: position20Banners,
            position14: position14Banners,
            position15: position15Banners,
        };

        yield put(getBannersSuccess(banners));
    } catch (error) {
        // console.log(error);
        yield put(getBannersFailure(error));
    }
}

export function* bannersWatcher() {
    yield takeEvery(getBanners.toString(), getSaga);
}
