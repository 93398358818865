import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import loadable from '@loadable/component';

import * as bem from 'b_';
import {
    Promo,
    Logo,
    MenuIcon,
    Search,
    Login,
    Menu,
    Submenu,
    Promotions,
} from 'components';

import './Header.css';
import { getNavbar } from 'store/ducks/menu/actions';
import { menuSelector, submenuSelector } from 'store/ducks/menu/selector';
import { MenuItem } from 'types/models';
import {
    selectPosition5Banner,
    selectPosition6Banner,
} from 'store/ducks/banners/selectors';
import Navbar from 'components/Navbar/Navbar';

enum PageName {
    Home = 'Home',
    About = 'About',
    Article = 'Article',
    ArticleVideo = 'ArticleVideo',
    Newsfeeds = 'Newsfeeds',
    Upcoming = 'Upcoming',
}

const menu = [
    { to: '/', exact: true, page: PageName.Home },
    { to: '/about', exact: true, page: PageName.About },
    { to: '/article', exact: true, page: PageName.Article },
    { to: '/newsfeeds', exact: true, page: PageName.Newsfeeds },
    { to: '/upcoming', exact: true, page: PageName.Upcoming },
];

const preloadPage = (pageName: string) =>
    loadable(() => import(`../../pages/${pageName}/${pageName}`));

const b = bem.with('header');

export function Header() {
    const dispatch = useDispatch();

    let history = useHistory();

    const [showNavbar, setShowNavbar] = useState(false);
    const [countPathname, setCountPathname] = useState(0);

    const status = useSelector(
        (state: { navbar: { status: string } }) => state.navbar.status
    );
    const menu: { visible: MenuItem[]; hidden: MenuItem[] } = useSelector(
        menuSelector
    );
    const submenu: MenuItem[] = useSelector(submenuSelector);

    const position5Banner = useSelector(selectPosition5Banner);
    const position6Banner = useSelector(selectPosition6Banner);

    useEffect(() => {
        if (showNavbar) {
            document.body.classList.add('_show-navbar');
        } else {
            document.body.classList.remove('_show-navbar');
        }
    }, [showNavbar]);

    useEffect(() => {
        setShowNavbar(false);

        let arrayPathname = history.location.pathname.split('/');

        setCountPathname(arrayPathname.length);
    }, [history.location.pathname]);

    useEffect(() => {
        if (status == 'IDLE') {
            dispatch(getNavbar());
        }
    }, []);

    return (
        <>
            <Navbar />
            <header className={b()}>
                <div className="header__content container">
                    {position5Banner &&
                        ((countPathname !== 5 &&
                            position5Banner &&
                            position5Banner.publications_hidden == '*') ||
                            (position5Banner &&
                                position5Banner.publications_hidden !==
                                    '*')) && (
                            <Promo
                                type="top"
                                img={position5Banner.img}
                                tag=""
                                link={position5Banner.url}
                                html={position5Banner.html}
                                id="position5Banner"
                                target={'position5Banner_' + position5Banner.id}
                            />
                        )}
                    <div className="header-top header__top">
                        <Logo />
                        <MenuIcon
                            setShowNavbar={setShowNavbar}
                            showNavbar={showNavbar}
                        />
                        <Search />
                        <Login />
                    </div>
                    <div className="header-middle">
                        {status == 'SUCCESS' && <Menu menu={menu} />}
                        <div className="header__inner">
                            {status == 'SUCCESS' && (
                                <Submenu submenu={submenu} />
                            )}
                            <Promotions />
                        </div>
                    </div>
                    {position6Banner &&
                        ((countPathname !== 5 &&
                            position5Banner &&
                            position5Banner.publications_hidden == '*') ||
                            (position5Banner &&
                                position5Banner.publications_hidden !==
                                    '*')) && (
                            <Promo
                                type="bottom"
                                img={position6Banner.img}
                                tag=""
                                link={position6Banner.url}
                                html={position6Banner.html}
                                id="position6Banner"
                                target={'position6Banner_' + position6Banner.id}
                            />
                        )}
                </div>
            </header>
        </>
    );
}
