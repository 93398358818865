import { prefixApiUrl, token } from 'config';
import fetch from 'isomorphic-fetch';

export const authRequest = (payload: { password: string; email: string }) => {
    let { password, email } = payload;

    let url = prefixApiUrl + 'api/login';

    const headers = {
        // Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    let body = {
        email: email,
        password: password,
    };

    return fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body),
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            // console.log('error', error)
        });
};
