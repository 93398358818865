import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import './Media.css';

declare global {
    interface Window {
        INFOX23802: any;
    }
}

export function Media(props: any) {
    let { id } = props;

    if (!id) {
        id = '';
    }

    const dispatch = useDispatch();

    // let { status, items } = useSelector(
    //     (state: { materials: { status: string; items: [] } }) => state.materials
    // );

    useEffect(() => {
        const script = document.createElement('script');

        script.type = 'text/javascript';
        script.async = true;
        script.src = 'https://smi2.ru/data/js/95735.js';
        script.charset = 'utf-8';

        document.body.appendChild(script);

        const script2 = document.createElement('script');

        script2.type = 'text/javascript';
        script2.async = true;
        script2.src = 'https://rb.infox.sg/infox/23802';
        script2.charset = 'utf-8';

        script2.onload = () => {
            window.INFOX23802.renderTo('infox_23802' + id);
        };

        document.body.appendChild(script2);
    }, []);

    return (
        <section className="media article-others__media">
            <div className="media__header">
                <h2 className="page-title media__title">СМИ</h2>
                <a href="#" className="more more--purple media__more">
                    Все материалы
                    <svg className="more__icon">
                        <use href="/img/sprite.svg#icons--arrow-right"></use>
                    </svg>
                </a>
            </div>
            <div className="media__body">
                <div className="c-default-block">
                    <div id="unit_95735"></div>
                </div>
                <div className="c-default-block">
                    <div id={`infox_23802${id}`}></div>
                </div>
            </div>
        </section>
    );
}
