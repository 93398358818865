import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { getInitdata, getInitdataSuccess, getInitdataIdle } from './actions';
import { StateStatus } from './types';

export const initialState: {
    status: string | any;
    categories: any;
    types: any;
    tags: any;
    persons: any;
    editorial: any;
    ip: any;
} = {
    status: 'IDLE',
    categories: {},
    types: {},
    tags: {},
    persons: {},
    editorial: [],
    ip: ''
};

const status = handleActions<StateStatus>(
    {
        [getInitdata]: () => 'LOADING',
        [getInitdataSuccess]: () => 'SUCCESS',
        [getInitdataIdle]: () => 'IDLE',
    },
    initialState.status
);

const categories = handleActions(
    {
        [getInitdataSuccess]: (state, action) =>
            action.payload.categoriesObject,
    },
    initialState.categories
);

const types = handleActions(
    {
        [getInitdataSuccess]: (state, action) => action.payload.typesObject,
    },
    initialState.types
);

const tags = handleActions(
    {
        [getInitdataSuccess]: (state, action) => action.payload.tagsObject,
    },
    initialState.tags
);

const persons = handleActions(
    {
        [getInitdataSuccess]: (state, action) => action.payload.personsObject,
    },
    initialState.persons
);

const editorial = handleActions(
    {
        [getInitdataSuccess]: (state, action) => action.payload.editorial,
    },
    initialState.editorial
);

const ip = handleActions(
    {
        [getInitdataSuccess]: (state, action) => action.payload.ip,
    },
    initialState.ip
);

export const initdata = combineReducers({
    status,
    categories,
    types,
    tags,
    persons,
    editorial,
    ip
});
