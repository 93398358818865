import { call, takeEvery, put, all } from 'redux-saga/effects';

import {
    getCategoriesRequest,
    getTypesRequest,
    getTagsRequest,
    getPersonsRequest,
    getEditorialRequest,
    getIpAddressRequest
} from './api';

import { getInitdata, getInitdataFailure, getInitdataSuccess } from './actions';

function* getSaga() {
    try {
        const [types, tags, persons, editorial]: any[] = yield all([
            // call(getCategoriesRequest),
            call(getTypesRequest),
            call(getTagsRequest),
            call(getPersonsRequest),
            call(getEditorialRequest),
            // call(getIpAddressRequest)
        ]);

        const ip = 0;

        let categoriesObject = {};
        // if (categories && categories.length > 0) {
        //     categories.forEach((category: { slug: string }) => {
        //         categoriesObject = {
        //             ...categoriesObject,
        //             [category.slug]: category,
        //         };
        //     });
        //     categoriesObject = {
        //         ...categoriesObject,
        //         all: { id: 0, title: 'Лента' },
        //     };
        // }

        let typesObject = {
            all: {
                id: 0,
                title: '',
                description: '',
                slug: ''
            }
        };
        if (types && types.length > 0) {
            types.forEach((type: { slug: string }) => {
                typesObject = {
                    ...typesObject,
                    [type.slug]: type,
                };
            });
        }

        let tagsObject = {};
        if (tags && tags.length > 0) {
            tags.forEach((type: { slug: string }) => {
                tagsObject = {
                    ...tagsObject,
                    [type.slug]: type,
                };
            });
        }

        let personsObject = {};
        if (persons && persons.length > 0) {
            persons.forEach((type: { slug: string }) => {
                personsObject = {
                    ...personsObject,
                    [type.slug]: type,
                };
            });
        }

        yield put(
            getInitdataSuccess({
                categoriesObject,
                typesObject,
                tagsObject,
                personsObject,
                editorial,
                ip
            })
        );
    } catch (error) {
        // console.log(error);
        yield put(getInitdataFailure(error));
    }
}

export function* getInitdataWatcher() {
    yield takeEvery(getInitdata.toString(), getSaga);
}
