import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { getBanners, getBannersSuccess, getBannersIdle } from './actions';
import { StateStatus } from 'types/models';
import { BannersState } from './types';

export const initialState: BannersState = {
    status: 'IDLE',
    items: [],
};

const status = handleActions<StateStatus>(
    {
        [getBanners]: () => 'LOADING',
        [getBannersSuccess]: () => 'SUCCESS',
        [getBannersIdle]: () => 'IDLE',
    },
    initialState.status
);

const items = handleActions(
    {
        [getBannersSuccess]: (state, action) => action.payload,
    },
    initialState.items
);

export const banners = combineReducers({
    status,
    items,
});
