import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setLike } from '../../store/ducks/likes/actions';
import cx from 'classnames';
import './Likes.css';
type Props = {
    postId: number;
    like: any;
    dislike: any;
    type: string;
};

export function Likes(props: Props) {
    const { postId, like, dislike, type } = props;

    const [likeCount, setLikeCount] = useState(0);
    const [dislikeCount, setDislikeCount] = useState(0);

    const [activeLike, setActiveLike] = useState(false);
    const [activeDislike, setActiveDislike] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        setLikeCount(like);
        setDislikeCount(dislike);
    }, [like, dislike]);

    const setActiveLocal = () => {
        if (typeof window !== 'undefined') {
            const idLocal =
                type === 'article'
                    ? localStorage.getItem(`Likes${postId}`)
                    : localStorage.getItem(`Comments${postId}`);
            const initialLikes = JSON.parse(idLocal);

            const {
                activeLikeLocal,
                activeDislikeLocal,
                postIdLocal,
            } = initialLikes ? initialLikes : '';

            if (postIdLocal == postId) {
                setActiveLike(activeLikeLocal);
                setActiveDislike(activeDislikeLocal);
            } else {
                setActiveLike(false);
                setActiveDislike(false);
            }
        }
    };

    useEffect(() => {
        setActiveLocal();
    }, [postId]);

    const handleLikeClick = (e: any) => {
        if (activeLike === false && activeDislike === false) {
            setActiveLike(true);
            setLikeCount(likeCount + 1);

            if (type === 'article') {
                dispatch(setLike({ id: postId, like: 1, type: 'post' }));
            } else {
                dispatch(setLike({ id: postId, like: 1, type: 'comment' }));
            }

            type === 'article'
                ? localStorage.setItem(
                      `Likes${postId}`,
                      JSON.stringify({
                          activeLikeLocal: true,
                          activeDislikeLocal: false,
                          postIdLocal: postId,
                      })
                  )
                : localStorage.setItem(
                      `Comments${postId}`,
                      JSON.stringify({
                          activeLikeLocal: true,
                          activeDislikeLocal: false,
                          postIdLocal: postId,
                      })
                  );
        }
    };

    const handleDislikeClick = (e: any) => {
        if (activeDislike === false && activeLike === false) {
            setActiveDislike(true);
            setDislikeCount(dislikeCount + 1);
            if (type === 'article') {
                dispatch(setLike({ id: postId, like: 0, type: 'post' }));
            } else {
                dispatch(setLike({ id: postId, like: 0, type: 'comment' }));
            }

            type === 'article'
                ? localStorage.setItem(
                      `Likes${postId}`,
                      JSON.stringify({
                          activeLikeLocal: false,
                          activeDislikeLocal: true,
                          postIdLocal: postId,
                      })
                  )
                : localStorage.setItem(
                      `Comments${postId}`,
                      JSON.stringify({
                          activeLikeLocal: false,
                          activeDislikeLocal: true,
                          postIdLocal: postId,
                      })
                  );
        }
    };

    return (
        <div
            className={cx(
                'article-likes',
                type === 'article' && 'article-footer__likes',
                type === 'comments' && 'comment__likes'
            )}
        >
            {type && type === 'article' && (
                <span className="article-likes__title">
                    Понравилась статья?
                </span>
            )}

            <div
                className={cx(
                    'article-likes__like',
                    activeLike === true && '_active _lock',
                    activeDislike && '_lock'
                )}
                onClick={handleLikeClick}
            >
                <svg className="article-likes__icon article-likes__icon--like">
                    <use href="/img/sprite.svg#icons--like"></use>
                </svg>
                <span className="article-likes__count article-likes__count--like">
                    {likeCount}
                </span>
            </div>
            <div
                className={cx(
                    'article-likes__dislike',
                    activeDislike === true && '_active _lock',
                    activeLike && '_lock'
                )}
                onClick={handleDislikeClick}
            >
                <svg className="article-likes__icon article-likes__icon--dislike">
                    <use href="/img/sprite.svg#icons--like"></use>
                </svg>
                <span className="article-likes__count article-likes__count--dislike">
                    {dislikeCount}
                </span>
            </div>
        </div>
    );
}
