import * as React from 'react';
import { Link } from 'react-router-dom';
import './Login.css';
import { getToken, removeToken } from 'config';
import { logout } from 'store/ducks/auth/actions';
import { useDispatch, useSelector } from 'react-redux';

export function Login() {
    const dispatch = useDispatch();

    const userAuth = useSelector(
        (state: { userAuth: { token: boolean } }) => state.userAuth.token
    );

    const handleLogout = (e: any) => {
        e.preventDefault();
        removeToken();
        dispatch(logout());
    };

    return (
        <div className="login header__login">
            {(!userAuth && (
                <></>
                // <Link to={'/authorization'} className="login__btn">
                //     <svg className="login__icon">
                //         <use href="/img/sprite.svg#icons--login"></use>
                //     </svg>
                //     <span className="login__title login__title--mobile">
                //         Войти
                //     </span>
                // </Link>
            )) || (
                <a
                    href="#"
                    onClick={e => handleLogout(e)}
                    className="login__btn"
                >
                    <svg className="login__icon">
                        <use href="/img/sprite.svg#icons--login"></use>
                    </svg>
                    <span className="login__title login__title--mobile">
                        Выйти
                    </span>
                </a>
            )}
        </div>
    );
}
