import { createActions } from 'redux-actions';

const {
    getArticle,
    getArticleSuccess,
    getArticleFailure,
    getArticleIdle,
    getArticleSsr,
    getArticleSsrSuccess,
    getArticleSsrFailure,
    getArticleSsrIdle,
}: {
    getArticle?: any;
    getArticleSuccess?: any;
    getArticleFailure?: any;
    getArticleIdle?: any;
    getArticleSsr?: any;
    getArticleSsrSuccess?: any;
    getArticleSsrFailure?: any;
    getArticleSsrIdle?: any;
} = createActions(
    'GET_ARTICLE',
    'GET_ARTICLE_SUCCESS',
    'GET_ARTICLE_FAILURE',
    'GET_ARTICLE_IDLE',
    'GET_ARTICLE_SSR',
    'GET_ARTICLE_SSR_SUCCESS',
    'GET_ARTICLE_SSR_FAILURE',
    'GET_ARTICLE_SSR_IDLE'
);

export {
    getArticle,
    getArticleSuccess,
    getArticleFailure,
    getArticleIdle,
    getArticleSsr,
    getArticleSsrSuccess,
    getArticleSsrFailure,
    getArticleSsrIdle,
};
