import loadable from '@loadable/component';
import { fetchAbout } from 'store/ducks/about/actions';
// import { fetchArticle } from 'store/ducks/article/actions';
import { getNewsfeeds } from 'store/ducks/newsfeeds/actions';
import { Router, Switch, Route, useLocation } from 'react-router-dom';
// import { fetchHomepage } from 'store/ducks/homepage/actions';
// import { fetchNews } from 'store/ducks/news/actions';
// import { getNews } from './components/News/actions';
// import { fetchShoes } from 'store/ducks/shoes/actions';
import { RouterFetchDataArgs } from 'types';
import { getPerson } from 'store/ducks/person/actions';
import {
    getHomepage,
    getMaterialsLoadMore,
} from 'store/ducks/homepage/actions';
import { getMaterials } from 'store/ducks/materials/actions';
import { getNewsfeed } from 'store/ducks/newsfeed/actions';
import { getNavbar } from 'store/ducks/menu/actions';
import {
    getArticle,
    getArticleSsr,
} from 'store/ducks/article/actions/articleActions';
import Fork from 'components/Fork/Fork';
import { getBanners } from 'store/ducks/banners/actions';
import { getInitdata } from 'store/ducks/app/actions';
import { getCommentsArticle } from 'store/ducks/comments/actions/commentsActions';
const AuthorPosts = loadable(() => import('./pages/AuthorPosts/AuthorPosts'));
const MissionPage = loadable(() => import('./pages/Mission/Mission'));
const RedactionPage = loadable(() => import('./pages/Redaction/Redaction'));
const AuthorsPage = loadable(() => import('./pages/Authors/Authors'));
const Policy = loadable(() => import('./pages/Policy/Policy'));
const MagazinePage = loadable(() => import('./pages/Magazine/Magazine'));

// const UpcomingPage = loadable(() => import('./pages/Upcoming/Upcoming'));
// const SneakersPage = loadable(() => import('./pages/Sneakers/Sneakers'));
const HomePage = loadable(() => import('./pages/Home/Home'));
const NotFoundPage = loadable(() => import('./pages/404/404'));
const About = loadable(() => import('./pages/About/About'));
const Newsfeeds = loadable(() => import('./pages/Newsfeeds/Newsfeeds'));
const Tags = loadable(() => import('./pages/Tags/Tags'));
const Article = loadable(() => import('./pages/Article/Article'));
const Materials = loadable(() => import('./pages/Materials/Materials'));
const Profile = loadable(() => import('./pages/Profile/Profile'));
const Landing = loadable(() => import('./pages/Landing/Landing'));

const Tvchannel = loadable(() => import('./pages/Tvchannel/Tvchannel'));

const TeleСalendar = loadable(() =>
    import('./pages/TeleСalendar/TeleСalendar')
);
const Tvchannels = loadable(() => import('./pages/Tvchannels/Tvchannels'));

const Authorization = loadable(() =>
    import('./pages/Authorization/Authorization')
);
const Restore = loadable(() => import('./pages/Restore/Restore'));

const Registration = loadable(() =>
    import('./pages/Registration/Registration')
);
const RegistrationMessage = loadable(() =>
    import('./pages/RegistrationMessage/RegistrationMessage')
);

/**
 * Routes are moved to a separate file,
 * so that you can use the asyncFetchData method on the component on the server (by url path)
 * which load all the necessary data for rendering the page.
 */
export default function renderRoutes(location: string) {
    return [
        {
            path: '/?message=email-verified',
            component: RegistrationMessage,
            exact: true,
            fetchData({ dispatch }: RouterFetchDataArgs) {
                dispatch(getInitdata());
                dispatch(getBanners());
                dispatch(getNavbar());
            },
        },
        {
            path: '/?message=email-already-verified',
            component: RegistrationMessage,
            exact: true,
            fetchData({ dispatch }: RouterFetchDataArgs) {
                dispatch(getInitdata());
                dispatch(getBanners());
                dispatch(getNavbar());
            },
        },
        {
            path: '/?message=no-user',
            component: RegistrationMessage,
            exact: true,
            fetchData({ dispatch }: RouterFetchDataArgs) {
                dispatch(getInitdata());
                dispatch(getBanners());
                dispatch(getNavbar());
            },
        },
        {
            path: '/',
            component: HomePage,
            exact: true,
            fetchData({ dispatch }: RouterFetchDataArgs) {
                dispatch(getInitdata());
                dispatch(getBanners());
                dispatch(getNavbar());
                dispatch(getHomepage());
                dispatch(getNewsfeed());
                // dispatch(getPerson());
                // dispatch(getMaterialsLoadMore({ page: 1 }));
            },
        },
        {
            path: '/?*',
            component: HomePage,
            exact: true,
            fetchData({ dispatch }: RouterFetchDataArgs) {
                dispatch(getInitdata());
                dispatch(getBanners());
                dispatch(getNavbar());
                dispatch(getHomepage());
                dispatch(getNewsfeed());
                // dispatch(getPerson());
                // dispatch(getMaterialsLoadMore({ page: 1 }));
            },
        },
        {
            path: '/magazine/redaction/policy',
            component: Policy,
            exact: true,
            fetchData({ dispatch }: RouterFetchDataArgs) {
                dispatch(getInitdata());
                dispatch(getBanners());
                dispatch(getNavbar());
            },
        },
        {
            path: '/magazine/about',
            component: MissionPage,
            exact: true,
            fetchData({ dispatch }: RouterFetchDataArgs) {
                dispatch(getInitdata());
                dispatch(getBanners());
                dispatch(getNavbar());
            },
        },
        {
            path: '/magazine/redaction',
            component: RedactionPage,
            exact: true,
            fetchData({ dispatch }: RouterFetchDataArgs) {
                dispatch(getInitdata());
                dispatch(getBanners());
                dispatch(getNavbar());
            },
        },
        {
            path: '/magazine/authors',
            component: AuthorsPage,
            exact: true,
            fetchData({ dispatch, match }: RouterFetchDataArgs) {
                dispatch(getInitdata());
                dispatch(getBanners());
                dispatch(getNavbar());
            },
            // fetchData({ dispatch }: RouterFetchDataArgs) {
            //     dispatch(fetchAbout());
            // },
        },
        {
            path: '/magazine/authors/:author',
            component: AuthorPosts,
            exact: true,
            fetchData({ dispatch }: RouterFetchDataArgs) {
                dispatch(getInitdata());
                dispatch(getBanners());
                dispatch(getNavbar());
            },
        },
        {
            path: '/magazine',
            component: MagazinePage,
            exact: true,
            fetchData({ dispatch }: RouterFetchDataArgs) {
                dispatch(getInitdata());
                dispatch(getBanners());
                dispatch(getNavbar());
            },
        },
        // {
        //     path: '/:taxonomy/:id',
        //     component: Article,
        //     exact: true,
        //     // fetchData({ dispatch }: RouterFetchDataArgs) {
        //     //     dispatch(getArticle());
        //     // },
        // },
        // {
        //     path: '/:taxonomy/:id/:id',
        //     component: Article,
        //     exact: true,
        //     // fetchData({ dispatch }: RouterFetchDataArgs) {
        //     //     dispatch(getArticle());
        //     // },
        // },
        {
            path: '/materials/:taxonomy/:type/:slug',
            component: Article,
            exact: true,
            fetchData({ dispatch, match }: RouterFetchDataArgs) {
                dispatch(getInitdata());
                dispatch(getBanners());
                dispatch(getNavbar());
                dispatch(getArticle({ slug: match.params.slug }));
            },
            // fetchData({ dispatch, match }: RouterFetchDataArgs) {
            // dispatch(getInitdata());
            // dispatch(getBanners());
            // dispatch(getNavbar());
            // dispatch(getCommentsArticle({ postId: match.params.slug }));
            // dispatch(getArticle({ slug: match.params.slug }));
            // },
        },
        {
            path: '/materials/:taxonomy',
            component: Newsfeeds,
            exact: true,
            fetchData(props: RouterFetchDataArgs) {
                const { dispatch, match } = props;

                const params = new URLSearchParams(location);
                let page = 1;
                if (params && params.get('page')) {
                    page = parseInt(params.get('page'));
                    if (isNaN(page)) {
                        page = 1;
                    }
                }
                const offsetCount = 21;
                dispatch(
                    getNewsfeeds({
                        taxonomy: match.params.taxonomy,
                        offsetCount: offsetCount,
                        page: page,
                        offset: (page - 1) * offsetCount,
                    })
                );
                dispatch(getInitdata());
                dispatch(getBanners());
                dispatch(getNavbar());
            },
        },
        {
            path: '/materials/persony/:person',
            component: Newsfeeds,
            exact: true,
            fetchData({ dispatch, match }: RouterFetchDataArgs) {
                dispatch(
                    getNewsfeeds({
                        taxonomy: 'persony',
                        type: match.params.person,
                    })
                );
                dispatch(getInitdata());
                dispatch(getBanners());
                dispatch(getNavbar());
            },
        },
        {
            path: '/materials/:taxonomy/:type',
            component: Newsfeeds,
            exact: true,
            fetchData({ dispatch, match }: RouterFetchDataArgs) {
                dispatch(getInitdata());
                dispatch(getBanners());
                dispatch(getNavbar());

                const params = new URLSearchParams(location);
                let page = 1;
                if (params && params.get('page')) {
                    page = parseInt(params.get('page'));
                    if (isNaN(page)) {
                        page = 1;
                    }
                }
                const offsetCount = 21;
                dispatch(
                    getNewsfeeds({
                        taxonomy: match.params.taxonomy,
                        type: match.params.type,
                        offsetCount: offsetCount,
                        page: page,
                        offset: (page - 1) * offsetCount,
                    })
                );
            },
            // fetchData({ dispatch }: RouterFetchDataArgs) {
            //     dispatch(fetchNewsfeeds());
            // },
        },
        {
            path: '/materials?*',
            component: Materials,
            exact: true,
            fetchData({ dispatch }: RouterFetchDataArgs) {
                dispatch(getInitdata());
                dispatch(getBanners());
                dispatch(getNavbar());
            },
            // fetchData({ dispatch }: RouterFetchDataArgs) {
            //     dispatch(fetchNewsfeeds());
            // },
        },
        {
            path: '/materials',
            component: Materials,
            exact: true,
            fetchData({ dispatch }: RouterFetchDataArgs) {
                dispatch(getInitdata());
                dispatch(getBanners());
                dispatch(getNavbar());
                dispatch(
                    getMaterials({
                        query: '',
                        sort_column: 'created_at',
                        offset: '0',
                        page: 1,
                    })
                );
            },
            // fetchData({ dispatch }: RouterFetchDataArgs) {
            //     dispatch(fetchNewsfeeds());
            // },
        },
        {
            path: '/tags',
            component: Materials,
            exact: true,
            fetchData({ dispatch }: RouterFetchDataArgs) {
                dispatch(
                    getMaterials({
                        query: '',
                        sort_column: 'created_at',
                        offset: '0',
                        page: 1,
                    })
                );
                dispatch(getInitdata());
                dispatch(getBanners());
                dispatch(getNavbar());
            },
        },
        {
            path: '/tags/:tag',
            component: Tags,
            exact: true,
            fetchData({ dispatch, match }: RouterFetchDataArgs) {
                dispatch(
                    getNewsfeeds({
                        taxonomy: 'tag',
                        type: match.params.tag,
                        offsetCount: 21,
                        offset: 0,
                    })
                );
                dispatch(getInitdata());
                dispatch(getBanners());
                dispatch(getNavbar());
            },
        },
        {
            path: '/authorization',
            component: Authorization,
            exact: true,
            fetchData({ dispatch }: RouterFetchDataArgs) {
                dispatch(getInitdata());
                dispatch(getBanners());
                dispatch(getNavbar());
            },
        },
        {
            path: '/authorization?*',
            component: Authorization,
            exact: true,
            fetchData({ dispatch }: RouterFetchDataArgs) {
                dispatch(getInitdata());
                dispatch(getBanners());
                dispatch(getNavbar());
            },
        },
        {
            path: '/registration',
            component: Registration,
            exact: true,
        },
        {
            path: '/restore',
            component: Restore,
            exact: true,
        },
        {
            path: '/restore?*',
            component: Restore,
            exact: true,
        },
        {
            path: '/profile',
            component: Profile,
            exact: true,
            fetchData({ dispatch }: RouterFetchDataArgs) {
                dispatch(getInitdata());
                dispatch(getBanners());
                dispatch(getNavbar());
            },
        },
        {
            path: '/tele-calendar',
            component: TeleСalendar,
            exact: true,
            fetchData({ dispatch }: RouterFetchDataArgs) {
                dispatch(getInitdata());
                dispatch(getBanners());
                dispatch(getNavbar());
            },
        },
        {
            path: '/tvchannels',
            component: Tvchannels,
            exact: true,
            fetchData({ dispatch }: RouterFetchDataArgs) {
                dispatch(getInitdata());
                dispatch(getBanners());
                dispatch(getNavbar());
            },
        },
        {
            path: '/Tvchannel',
            component: Tvchannel,
            exact: true,
            // fetchData({ dispatch }: RouterFetchDataArgs) {
            //     dispatch(fetchNewsfeeds());
            // },
        },
        {
            path: '/landing',
            component: Landing,
            exact: true,
            // fetchData({ dispatch }: RouterFetchDataArgs) {
            //     dispatch(fetchNewsfeeds());
            // },
        },
        {
            path: '*',
            component: NotFoundPage,
            exact: true,
            fetchData({ dispatch }: RouterFetchDataArgs) {
                dispatch(getInitdata());
                dispatch(getBanners());
                dispatch(getNavbar());
            },
        },
    ];
}
