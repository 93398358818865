// import { token } from 'config';
import { prefixApiUrl } from 'config';
import fetch from 'isomorphic-fetch';

export const addArticleCommentsRequest = (payload: any) => {
    const postId: any = payload.postId;
    const token: string = payload.token;
    const text: any = payload.data.text;
    const url = prefixApiUrl + 'api/comment/create';

    return new Promise(resolve => {
        const headers = {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        };

        let body = {
            text: `${text}`,
            // user_id: 18,
            post_id: postId,
            token: token,
        };

        fetch(url, {
            method: 'POST',
            headers,
            body: JSON.stringify(body),
        })
            .then(response => response.json())
            .then(data => {
                resolve(data);
            });
    });
};
