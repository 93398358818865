import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import {
    auth,
    authFailure,
    authIdle,
    authSuccess,
    authSetToken,
    logout,
} from './actions';

import { StateStatus } from 'types/models';

import { AuthState } from './types';

export const initialState: AuthState = {
    status: 'IDLE',
    token: false,
    message: '',
};

const token = handleActions(
    {
        [authSuccess]: (state, action) => action.payload.token,
        [authFailure]: (state, action) => action.payload.token,
        [authSetToken]: (state, action) => action.payload,
        [logout]: (state, action) => false,
    },
    initialState.token
);

const message = handleActions(
    {
        [authSuccess]: (state, action) => action.payload.message,
        [authFailure]: (state, action) => action.payload.message,
    },
    initialState.message
);

const status = handleActions<StateStatus>(
    {
        [auth]: () => 'LOADING',
        [authSuccess]: () => 'SUCCESS',
        [authIdle]: () => 'IDLE',
        [logout]: () => 'IDLE',
    },
    initialState.status
);

export const userAuth = combineReducers({
    status,
    token,
    message,
});
