import { createActions } from 'redux-actions';

const {
    getBanners,
    getBannersSuccess,
    getBannersFailure,
    getBannersIdle,
}: {
    getBanners?: any;
    getBannersSuccess?: any;
    getBannersFailure?: any;
    getBannersIdle?: any;
} = createActions(
    'GET_BANNERS',
    'GET_BANNERS_SUCCESS',
    'GET_BANNERS_FAILURE',
    'GET_BANNERS_IDLE'
);

export { getBanners, getBannersSuccess, getBannersFailure, getBannersIdle };
