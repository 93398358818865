import { createSelector } from 'reselect';

import { Post } from 'types/models';

import { getMetaField } from 'config';
import moment from 'moment';

const events = (state: { homepage: { events: Post[] } }) =>
    state.homepage.events;

export const eventsSelector = createSelector([events], items => {
    let data: any[] = [];

    if (items && items.length) {
        items.map((item: Post, index: number) => {
            let dateArticle = item.created_at;
            if (item.publication_at) {
                dateArticle = item.publication_at;
            }
            if(item){
                data.push({
                    id: item.id,
                    key: index,
                    link: item.categories_path + '/' + item.slug,
                    type: (!index && 'main') || '',
                    text: item.title,
                    date: moment(dateArticle).format('DD.MM.YYYY'),
                    time: moment(dateArticle).format('HH:mm'),
                });
            }
        });
    }

    return data;
});
