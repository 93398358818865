import { prefixApiUrl, token } from 'config';
import fetch from 'isomorphic-fetch';

export const getUserRequest = (payload: { id: string }) => {
    let { id } = payload;

    const url = prefixApiUrl + 'api/user/' + id;

    const headers = {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    return fetch(url, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => {
            return data;
        });
};
