import { createActions } from 'redux-actions';

const {
    getNavbar,
    getNavbarSuccess,
    getNavbarFailure,
    getNavbarIdle,
}: {
    getNavbar?: any;
    getNavbarSuccess?: any;
    getNavbarFailure?: any;
    getNavbarIdle?: any;
} = createActions(
    'GET_NAVBAR',
    'GET_NAVBAR_SUCCESS',
    'GET_NAVBAR_FAILURE',
    'GET_NAVBAR_IDLE'
);

export { getNavbar, getNavbarSuccess, getNavbarFailure, getNavbarIdle };
