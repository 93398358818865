import { pathMaterials } from 'config';
import { createSelector } from 'reselect';
import { MenuItem, PostTaxonomyItem } from 'types/models';
import { useParams } from 'react-router-dom';

const menu = (state: { navbar: { menu: PostTaxonomyItem[] } }) =>
    state.navbar.menu;

export const menuSelector = createSelector([menu], data => {
    let visible: MenuItem[] = [],
        hidden: MenuItem[] = [];

    if (data && data.length) {
        data.map((item: MenuItem) => {
            item = {
                ...item,
                slug: pathMaterials + '/' + item.slug,
            };
            if (item.menu_header == 1){
                visible.push(item);
            } else if (item.menu_header == 2) {
                hidden.push(item);
            }
            // if (item.id == 21481 || item.id == 21478) {
            //     hidden.push(item);
            // } else if (item.id == 21492 || item.id == 21491 || item.id == 21490 || item.id == 21489 || item.id == 21488 || item.id == 21487 || item.id == 21486) {
            //     visible.push(item);
            // } else if (item.id == 21493) {
            //     visible.push(item);
            // }
        });
    }

    return {
        visible,
        hidden,
    };
});

const submenu = (state: { navbar: { submenu: PostTaxonomyItem[] } }) =>
    state.navbar.submenu;

export const submenuSelector = createSelector([submenu], data => {
    let list: MenuItem[] = [];

    if (data && data.length) {
        data.map((item: MenuItem) => {
            item = {
                ...item,
                slug: pathMaterials + '/all/' + item.slug,
            };
            list.push(item);
        });
    }

    return list;
});
