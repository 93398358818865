import * as React from 'react';
import './PostNewsfeed.css';
import { Tag } from 'components';
import { Link } from 'react-router-dom';
type Props = {
    key: string;
    link: string;
    type: string;
    text: string;
    date: string;
    time: string;
};

export function PostNewsfeed(props: Props) {
    const { link, type, text, date, time } = props;
    return (
        <>
            {type && type === 'main' && (
                <article className="post-newsfeed post-newsfeed--main newsfeed__post-newsfeed newsfeed__post-newsfeed--main">
                    <Link to={link}>
                        <h3
                            className="post-newsfeed__title"
                            dangerouslySetInnerHTML={{ __html: text }}
                        ></h3>
                        <div className="post-newsfeed__subtitle">
                            <span
                                className="post-newsfeed__date"
                                dangerouslySetInnerHTML={{ __html: date }}
                            ></span>
                            &nbsp;|&nbsp;
                            <span
                                className="post-newsfeed__time"
                                dangerouslySetInnerHTML={{ __html: time }}
                            ></span>
                        </div>
                    </Link>
                </article>
            )}
            {!type && (
                <article className="post-newsfeed">
                    <Link to={link}>
                        <h3
                            className="post-newsfeed__title"
                            dangerouslySetInnerHTML={{ __html: text }}
                        ></h3>
                        <div className="post-newsfeed__subtitle">
                            <span
                                className="post-newsfeed__date"
                                dangerouslySetInnerHTML={{ __html: date }}
                            ></span>
                            &nbsp;|&nbsp;
                            <span
                                className="post-newsfeed__time"
                                dangerouslySetInnerHTML={{ __html: time }}
                            ></span>
                        </div>
                    </Link>
                </article>
            )}
        </>
    );
}
