import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import {
    getArticle,
    getArticleIdle,
    getArticleSuccess,
    getArticleFailure
} from './actions/articleActions';

import {
    getArticleList,
    getArticleListIdle,
    getArticleListSuccess,
} from './actions/articleListActions';

import { ArticleState, StateStatus } from './types';
// import { StateStatus } from '../../../types/models';
export const initialState: ArticleState = {
    status: 'IDLE',
    items: [],
    statusArticleList: 'IDLE',
    itemsArticleList: [],
    // articleListTotalPages: null,
};

const status = handleActions<StateStatus>(
    {
        [getArticle]: () => 'LOADING',
        [getArticleSuccess]: () => 'SUCCESS',
        [getArticleIdle]: () => 'IDLE',
        [getArticleFailure]: () => 'FAILURE',
    },
    initialState.status
);

const items = handleActions(
    {
        [getArticleSuccess]: (state, action) => action.payload,
    },
    initialState.items
);

const statusArticleList = handleActions<StateStatus>(
    {
        [getArticleList]: () => 'LOADING',
        [getArticleListSuccess]: () => 'SUCCESS',
        [getArticleListIdle]: () => 'IDLE',
    },
    initialState.statusArticleList
);

const itemsArticleList = handleActions(
    {
        [getArticleListSuccess]: (state, action) => action.payload,
    },
    initialState.itemsArticleList
);

// const articleListTotalPages = handleActions(
//     {
//         [getArticleListSuccess]: (
//             state,
//             action: {
//                 payload: {
//                     paginate: { current_page: number; total_pages: number };
//                 };
//             }
//         ) => {
//             return action.payload.paginate.total_pages;
//         },
//     },
//     initialState.articleListTotalPages
// );

export const article = combineReducers({
    status,
    items,
    statusArticleList,
    itemsArticleList,
    // articleListTotalPages,
});
