import { call, takeEvery, put, all } from 'redux-saga/effects';
import { regRequest } from './api';
import {
    registerUser,
    registerUserFailure,
    registerUserSuccess,
} from './actions';

function* registerUserSaga(action: any) {
    try {
        const { payload } = action;
        const result: any[] = yield call(regRequest, payload);
        yield put(registerUserSuccess(result));
    } catch (error) {
        // console.log('error: ', error);
        yield put(registerUserFailure(error));
    }
}

export function* registerUserWatcher() {
    yield takeEvery(registerUser.toString(), registerUserSaga);
}
