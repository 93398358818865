import { createActions } from 'redux-actions';

const {
    getAuthors,
    getAuthorsSuccess,
    getAuthorsFailure,
    getAuthorsIdle,
}: {
    getAuthors?: any;
    getAuthorsSuccess?: any;
    getAuthorsFailure?: any;
    getAuthorsIdle?: any;
} = createActions(
    'GET_AUTHORS',
    'GET_AUTHORS_SUCCESS',
    'GET_AUTHORS_FAILURE',
    'GET_AUTHORS_IDLE'
);

export {
    getAuthors,
    getAuthorsSuccess,
    getAuthorsFailure,
    getAuthorsIdle
};
