import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import {
    restoreUser,
    restoreUserFailure,
    restoreUserIdle,
    restoreUserSuccess,
    renewUser,
    renewUserFailure,
    renewUserIdle,
    renewUserSuccess,
} from './actions';

import { StateStatus } from 'types/models';

import { RestoreState } from './types';

export const initialState: RestoreState = {
    status: 'IDLE',
    messages: {},
};

const status = handleActions<StateStatus>(
    {
        [restoreUser]: () => 'LOADING',
        [restoreUserSuccess]: () => 'SUCCESS',
        [restoreUserFailure]: () => 'FAILURE',
        [restoreUserIdle]: () => 'IDLE',
    },
    initialState.status
);

const messages = handleActions(
    {
        [restoreUserSuccess]: (state, action) => action.payload,
    },
    initialState.messages
);

const renewStatus = handleActions<StateStatus>(
    {
        [renewUser]: () => 'LOADING',
        [renewUserSuccess]: () => 'SUCCESS',
        [renewUserFailure]: () => 'FAILURE',
        [renewUserIdle]: () => 'IDLE',
    },
    initialState.status
);

const renewMessages = handleActions(
    {
        [renewUserSuccess]: (state, action) => action.payload,
    },
    initialState.messages
);

export const userRestore = combineReducers({
    status,
    messages,
    renewStatus,
    renewMessages,
});
