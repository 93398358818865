import { createSelector } from 'reselect';

import { Post } from 'types/models';

import { getMetaField, getPreview, pathPersony } from 'config';
import moment from 'moment';

const persons = (state: { homepage: { persons: Post[] } }) =>
    state.homepage.persons;

export const personsSelector = createSelector([persons], items => {
    let data: any[] = [];

    if (items && items.length) {
        items.map((item: Post) => {
            if(item){
                data.push({
                    key: item.id,
                    id: item.id,
                    slug: pathPersony + '/' + item.slug,
                    preview: item.img,
                    title: (item.short_title && item.short_title) || item.title,
                });
            }
        });
    }

    return data;
});
