import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getNews, getNewsSuccess } from './actions';
import { newsRequest } from './api';
import { News } from './types';

function* getNewsSaga() {
    try {
        const response: News = yield call(newsRequest);
        yield put(getNewsSuccess(response));
    } catch (e) {
        // console.log(e);
    }
}

export function* newsWatcher() {
    yield takeLatest(getNews.toString(), getNewsSaga);
}
