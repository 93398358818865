import { createActions } from 'redux-actions';

const {
    auth,
    authSuccess,
    authFailure,
    authIdle,
    authSetToken,
    logout,
}: {
    auth?: any;
    authSuccess?: any;
    authFailure?: any;
    authIdle?: any;
    authSetToken?: any;
    logout?: any;
} = createActions(
    'AUTH',
    'AUTH_SUCCESS',
    'AUTH_FAILURE',
    'AUTH_IDLE',
    'AUTH_SET_TOKEN',
    'LOGOUT'
);

export { auth, authSuccess, authFailure, authIdle, authSetToken, logout };
