import { createActions } from 'redux-actions';

const {
    getArticleList,
    getArticleListSuccess,
    getArticleListFailure,
    getArticleListIdle,
}: {
    getArticleList?: any;
    getArticleListSuccess?: any;
    getArticleListFailure?: any;
    getArticleListIdle?: any;
} = createActions(
    'GET_ARTICLE_LIST',
    'GET_ARTICLE_LIST_SUCCESS',
    'GET_ARTICLE_LIST_FAILURE',
    'GET_ARTICLE_LIST_IDLE'
);

export {
    getArticleList,
    getArticleListSuccess,
    getArticleListFailure,
    getArticleListIdle,
};
