import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { getUser, getUserSuccess, getUserIdle } from './actions';
import { UsersState } from './types';
import { StateStatus } from 'types/models';

export const initialState: UsersState = {
    status: 'IDLE',
    list: {},
};

const status = handleActions<StateStatus>(
    {
        [getUser]: () => 'LOADING',
        [getUserSuccess]: () => 'SUCCESS',
        [getUserIdle]: () => 'IDLE',
    },
    initialState.status
);

const list = handleActions(
    {
        [getUserSuccess]: (state, action) => {
            return {
                ...action.payload,
                [action.payload.id]: action.payload,
            };
        },
    },
    initialState.list
);

export const users = combineReducers({
    status,
    list,
});
