import { createActions } from 'redux-actions';

const {
    addCommentsArticle,
    addCommentsArticleSuccess,
    addCommentsArticleFailure,
    addCommentsArticleIdle,
}: {
    addCommentsArticle?: any;
    addCommentsArticleSuccess?: any;
    addCommentsArticleFailure?: any;
    addCommentsArticleIdle?: any;
} = createActions(
    'ADD_COMMENTS_ARTICLE',
    'ADD_COMMENTS_ARTICLE_SUCCESS',
    'ADD_COMMENTS_ARTICLE_FAILURE',
    'ADD_COMMENTS_ARTICLE_IDLE'
);

export {
    addCommentsArticle,
    addCommentsArticleSuccess,
    addCommentsArticleFailure,
    addCommentsArticleIdle,
};
