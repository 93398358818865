import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MenuItem } from 'types/models';
import './Submenu.css';

type Props = {
    submenu: MenuItem[];
};

export function Submenu(props: Props) {
    const { submenu } = props;

    const [menu, setMenu] = useState(submenu);

    return (
        <div className="submenu submenu--mobile header__submenu">
            <ul className="submenu__list">
                {menu &&
                    menu.length > 0 &&
                    menu.map((item: MenuItem, index: number) => {
                        return (
                            <li className="submenu__item" key={index}>
                                <Link to={item.slug} className="submenu__link">
                                    {item.title}
                                </Link>
                            </li>
                        );
                    })}
                <li className="submenu__item _parent">
                    <span>Спецпроекты</span>
                    <ul>
                        <li>
                            <a href="/5g" target="_blank">
                                5G в России
                            </a>
                        </li>
                        <li>
                            <a href="/ndo" target="_blank">
                                Цифровая экономика
                            </a>
                        </li>
                        <li>
                            <a href="/tvconcept" target="_blank">
                                Будущее ТВ в РФ
                            </a>
                        </li>
                        <li>
                            <a href="/files/ft.pdf" target="_blank">
                                Таблица частот
                            </a>
                        </li>
                        <li>
                            <a href="/quarter" target="_blank">
                                Нам 25 лет
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    );
}
