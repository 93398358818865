import * as React from 'react';
import { Link } from 'react-router-dom';
import './Logo.css';

export function Logo() {
    return (
        <Link to="/" className="logo header__logo">
            <img className="logo__img" src="/img/icons/logo-short.svg" alt="" />
            <svg className="logo__img _mobile">
                <use href="/img/sprite.svg#icons--logo"></use>
            </svg>
        </Link>
    );
}
